import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromActions from './homepage-module.actions';
import { catchError, switchMap, map } from 'rxjs';
import { ErrorHandlerService, HomepageModuleFacade } from '../../services';

@Injectable()
export class HomepageModuleEffects {
  constructor(
    private actions$: Actions,
    private homepageModuleFacade: HomepageModuleFacade,
    private errorHandlerService: ErrorHandlerService
  ) {}

  getHomepageModuleToken$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.GetHomepageModuleToken),
      switchMap(() =>
        this.homepageModuleFacade.getHomepageModuleToken().pipe(
          map((token: string) =>
            fromActions.GetHomepageModuleTokenSuccess({
              token,
            })
          ),
          catchError((error: Error) => {
            this.errorHandlerService.handleError(
              '(REQUEST ERROR) on homepage-module.effects.ts:getHomepageModuleToken$:',
              error
            );
            return [fromActions.GetHomepageModuleTokenFailed({ error })];
          })
        )
      )
    );
  });
}
