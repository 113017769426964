import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { getHomepageModuleTokenQuery } from '../../queries';
import { IHomepageModuleResponse } from '../../models';
import { Observable, map } from 'rxjs';

@Injectable()
export class HomepageModuleFacade {
  constructor(private apollo: Apollo) {}

  public getHomepageModuleToken(): Observable<string> {
    return this.apollo
      .query<{ getActiveHomepageModuleByCustomer: IHomepageModuleResponse }>({
        query: getHomepageModuleTokenQuery,
      })
      .pipe(
        map(response => {
          return response?.data?.getActiveHomepageModuleByCustomer?.token;
        })
      );
  }
}
