@if (ticketsActionState.pending === false) {
  <div class="row row-gap-3">
    <ng-content select="[new-ticket-info]"></ng-content>
    @if (createButtonText && hasActiveContracts) {
      <rs-web-card
        class="col-lg-4 col-xxl-3"
        [style]="{ '--card-background-color': 'white' }"
        [hover]="true"
        [borderStyle]="'dashed'"
        (clickEvent)="createTicket.emit()"
      >
        <div class="tickets-create-ticket">
          <rs-web-icon
            [icon]="immomioIconName.Plus"
            [color]="'var(--color-primary)'"
            [size]="12"
          ></rs-web-icon>
          <span class="ms-2">{{ createButtonText | translate }}</span>
        </div>
      </rs-web-card>
    }
    @for (ticket of tickets; track ticket.node.id) {
      <rs-web-ticket-card
        class="col-sm-6 col-lg-4 col-xxl-3"
        [ticket]="ticket.node"
        (click)="openTicket.emit(ticket.node)"
      ></rs-web-ticket-card>
    }
    @if (tickets.length === 0) {
      <rs-web-no-data [text]="txtNoTickets" className="h6"></rs-web-no-data>
    }

    @if (hasNextPage) {
      <rs-web-card class="col-sm-6 col-lg-4 col-xxl-3" [hover]="true" [elevation]="2">
        <div
          class="tickets-card__load-more h-100 d-flex justify-content-center align-items-center"
          (click)="loadMoreTickets.emit()"
        >
          <rs-web-icon
            [icon]="immomioIconName.Plus"
            [color]="'var(--color-primary)'"
            [size]="12"
          ></rs-web-icon>
          <span class="ms-2">{{ 'general.show_more_b' | translate }}</span>
        </div>
      </rs-web-card>
    }
  </div>
} @else {
  <rs-web-loading-skeleton
    [show]="true"
    [types]="[LoadingType.TICKETCARD]"
  ></rs-web-loading-skeleton>
}
