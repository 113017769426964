@if ((metersByContractActionState$ | async).pending) {
  <rs-web-loading-skeleton
    [show]="true"
    [types]="[LoadingType.HEADER, LoadingType.CONSUMPTION]"
  ></rs-web-loading-skeleton>
} @else {
  <div class="d-flex flex-column position-relative mb-4">
    <h4 class="mobile title-m">{{ 'dashboard.consumption_widget_l' | translate }}</h4>
    <rs-web-contract-menu
      [contractMenuDesign]="contractMenuDesign.DROPDOWN"
      [iconSize]="12"
      [immomioIconName]="ImmomioIconName.ContextMenu"
      [showIcon]="true"
      class="mb-3 mobile"
    ></rs-web-contract-menu>
    <rs-web-consumption-dashboard-cards
      [metersByContract]="metersByContract$ | async"
    ></rs-web-consumption-dashboard-cards>
  </div>
}
