<rs-web-card
  (click)="tap()"
  [elevation]="elevation"
  [hover]="hover"
  [withoutPadding]="true"
  class="content-card"
>
  <div [class.content-card__header--offer]="preset === presets.OFFER" class="content-card__header">
    @if (image) {
      <div class="content-card__header__image">
        <rs-web-image
          [defaultSrc]="image"
          [objectFit]="imageObjectFit"
          [borderRadius]="imageBoarderRadius"
          [borderStyle]="imageBorderStyle"
          [centerImage]="preset === presets.NEWS ? true : false"
          [dataTableCellPicture]="preset === presets.NEWS ? true : false"
          [centerImageSize]="preset === presets.NEWS ? centerImageSize : undefined"
        ></rs-web-image>
      </div>
    }
    <span class="content-card__header__title primary-text">{{ title | translate }}</span>
  </div>
  <div class="content-card__body">
    <p
      [innerHtml]="removeHTML(text) | translate"
      [ngClass]="preset !== presets.OFFER ? 'secondary-text' : 'primary-text'"
      class="content-card__text"
    ></p>
  </div>
  @if (date) {
    @let dateColor =
      preset !== presets.OFFER
        ? 'var(--color-text-secondary, #193f70)'
        : 'var(--color-text-primary, #3486ef)';
    <div class="content-card__bottom">
      <rs-web-icon [size]="16" [icon]="footerIcon" [color]="dateColor"></rs-web-icon>
      <div [style.color]="dateColor">
        {{ date | rsWebDate }}
      </div>
    </div>
  }
</rs-web-card>
