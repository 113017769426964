import { Component, ElementRef, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import {
  GetHomepageModuleToken,
  HomepageBaseComponent,
  IEnvironment,
  IHomepageModelState,
} from '@resident-nx/shared';
import { Observable, filter, map } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { AsyncPipe } from '@angular/common';
import { LoaderWebComponent } from '../../atoms/loader/loader.component';

@Component({
  selector: 'rs-web-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss'],
  standalone: true,
  imports: [LoaderWebComponent, AsyncPipe, TranslateModule],
})
export class HomePageWebComponent extends HomepageBaseComponent implements OnInit {
  public url$: Observable<SafeResourceUrl>;
  public iFrameHeight = 200;

  @ViewChild('iframe') iFrame: ElementRef;
  @HostListener('window:message', ['$event']) onMessage(e) {
    if (e.origin === this.environment.appUrlHomepage) {
      this.iFrameHeight = e.data;
    }
  }

  constructor(
    public store: Store<IHomepageModelState>,
    private sanitizer: DomSanitizer,
    @Inject('ENVIRONMENT') private environment: IEnvironment
  ) {
    super(store);
  }

  ngOnInit(): void {
    this.url$ = this.token$.pipe(
      filter(Boolean),
      map(token =>
        this.sanitizer.bypassSecurityTrustResourceUrl(
          `${this.environment.appUrlHomepage}/de/properties?token=${token}`
        )
      )
    );
    this.store.dispatch(GetHomepageModuleToken());
  }

  onLoad(): void {
    if (this.iFrame) {
      this.iFrame.nativeElement.contentWindow.postMessage('ready', '*');
    }
  }
}
