import { createAction, props } from '@ngrx/store';

export const GetHomepageModuleToken = createAction('[Homepage Module] Get Homepage-Module Token');

export const GetHomepageModuleTokenSuccess = createAction(
  '[Homepage Module] Get Homepage-Module Token Success',
  props<{ token: string }>()
);

export const GetHomepageModuleTokenFailed = createAction(
  '[Homepage Module] Get Homepage-Module Token Failed',
  props<{ error: Error }>()
);
